import {
    BreadcrumbFragment,
    CardFragment,
    ContentMatrixFragment,
    PageCardFragment,
    PageDocument,
    PageFragment,
    PageQuery,
    PageQueryVariables,
} from '../../generated/graphql';
import Seo from '../Seo';
import Hero from '../Hero';
import { heroTransformToProps } from '../../constants/imageTransforms';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import { Box } from '@rebass/grid';
import Breadcrumb from '../Breadcrumb';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import ContentMatrix from '../ContentMatrix';
import React, { FC, useMemo } from 'react';
import useAnalyticsContentCategory from '@oberoninternal/travelbase-website/dist/hooks/analytics/useAnalyticsContentCategory';
import SearchWrapper from '../layout/SearchWrapper';
import PageWrapper from '@oberoninternal/travelbase-website/dist/components/Page';
import { gql } from '@apollo/client';
import initApolloClient, { PreviewData } from '../../initApolloClient';
import { GetStaticPaths, GetStaticProps } from 'next';
import { addApolloState } from '@oberoninternal/travelbase-website/dist/createApolloClient';
import addGlobalQuery from '../../utils/addGlobalQuery';
import { DEFAULT_REVALIDATION_TIME } from '../../constants/revalidationTime';
import Head from 'next/head';
import removeTrailingSlash from 'remove-trailing-slash';
import path from 'path';

const SITE_ID_MAP = {
    1: 'nl',
    2: 'de',
    3: 'en',
};
interface Props {
    entry: PageFragment;
    breadcrumb?: BreadcrumbFragment[];
}

export const query = gql`
    query Page($uri: String, $lang: String, $retourUri: String) {
        entry(uri: [$uri], site: [$lang]) {
            ...Page
        }
        retourResolveRedirect(uri: $retourUri) {
            redirectDestUrl
            redirectSrcUrl
            redirectHttpCode
            redirectSrcMatch
            siteId
        }
    }

    fragment Page on pages_page_Entry {
        uri
        title
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        settingsNotSearchable
        heroImage {
            ...HeroTransform
        }
        heroImageVisible
        root
        contentMatrix {
            ...ContentMatrix
        }

        children {
            ...Card
        }

        id
        parent {
            id

            children(section: ["pages"]) {
                ...Card
            }
        }
        settingsSearchTags {
            title
        }

        localized {
            url
            language
        }
    }

    query PagePaths {
        entries(site: ["*"], type: ["page"]) {
            __typename
            uri
            slug
            language
        }
    }
`;

const Page: FC<React.PropsWithChildren<Props>> = ({ entry, breadcrumb }) => {
    const pageSiblings = useMemo(
        () =>
            entry.parent?.children
                ?.filter((child): child is CardFragment & PageCardFragment => child?.__typename === 'pages_page_Entry')
                .filter(page => !page.settingsNotSearchable)
                .filter(page => page.uri !== entry.uri) ?? [],
        [entry]
    );
    const pageChildren = useMemo(
        () =>
            entry.children
                ?.filter((child): child is PageCardFragment => child?.__typename === 'pages_page_Entry')
                .filter(page => !page.settingsNotSearchable) ?? [],
        [entry]
    );
    const matrix = entry.contentMatrix?.filter((field): field is ContentMatrixFragment => !!field) ?? [];

    const hasAccoBlocks = matrix.some(item => item.__typename === 'contentMatrix_sectionAccommodations_BlockType');

    useAnalyticsContentCategory(
        // if the page has an acco block, <Search /> will also call useAnalyticsContentCategory, which is enough.
        !hasAccoBlocks ? 'page' : undefined
    );
    const isHeroVisible = !hasAccoBlocks && entry.heroImage?.[0] && entry.heroImageVisible;
    const Wrapper: FC<React.PropsWithChildren<unknown>> = hasAccoBlocks
        ? SearchWrapper
        : ({ children }) => <>{children}</>;
    const heroImage = entry.heroImage?.[0];
    return (
        <PageWrapper>
            <Seo
                image={heroImage?.ogImageUrl ?? ''}
                settingsMetaDescription={entry.settingsMetaDescription ?? ''}
                settingsSeoTitle={entry.settingsSeoTitle ?? entry.title ?? ''}
                alternates={entry.localized}
            />
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {isHeroVisible && <Hero {...heroTransformToProps(entry.heroImage![0]!)} />}

            {entry.settingsNotSearchable ? (
                <Head>
                    <meta name="robots" content="noindex,nofollow" />
                </Head>
            ) : null}

            <Wrapper>
                <ContentWrapper>
                    <Box mt={[3, 8]} mb={[3, 5]}>
                        <Breadcrumb menu={breadcrumb} uri={entry.uri} />

                        <Title variant="large">{entry.title}</Title>
                    </Box>
                </ContentWrapper>

                <Box mb={[5, 8]}>
                    <ContentMatrix matrix={matrix} pageSiblings={pageSiblings} pageChildren={pageChildren} />
                </Box>
            </Wrapper>
        </PageWrapper>
    );
};

export const getStaticProps: GetStaticProps = async context => {
    if (context.locale === 'default') return { props: {} };

    const { previewData } = context;
    const client = initApolloClient(context.locale as string, { previewData: previewData as PreviewData });
    const lang = context.locale as string;
    const uri = (context.params?.uri as string[]).join('/');

    const { data } = await client.query<PageQuery, PageQueryVariables>({
        query: PageDocument,
        variables: {
            uri,
            lang,
            retourUri: `/${uri}`,
        },
    });

    if (!data.entry) {
        const { retourResolveRedirect } = data;

        if (retourResolveRedirect) {
            const { redirectDestUrl, redirectHttpCode, redirectSrcMatch, redirectSrcUrl, siteId } =
                retourResolveRedirect;
            if (redirectDestUrl && redirectSrcUrl) {
                const srcUrl = new URL(redirectSrcUrl, 'http://contoso.com');
                const destUrl = new URL(redirectDestUrl, 'http://contoso.com');
                let srcPathname = removeTrailingSlash(srcUrl.pathname).toLowerCase();
                let destPathname = removeTrailingSlash(destUrl.pathname).toLowerCase();

                if (redirectSrcMatch !== 'fullurl') {
                    const locale = SITE_ID_MAP[siteId as keyof typeof SITE_ID_MAP];

                    if (locale && locale !== null) {
                        destPathname = path.join('/', locale, destPathname);
                    }
                }

                srcPathname = path.join(srcPathname, '/');
                destPathname = path.join(destPathname, '/');

                // normalization, because this redirect data is pure trash and it contains lots of infinite loops.
                if (srcPathname !== destPathname) {
                    return {
                        redirect: {
                            permanent: redirectHttpCode === 301,
                            destination: redirectDestUrl,
                        },
                        revalidate: DEFAULT_REVALIDATION_TIME,
                    };
                }
            }
        }
        return {
            notFound: true,
        };
    }

    const suppressCookieConsent = !(
        (data.entry as { settingsCookieConsent?: boolean })?.settingsCookieConsent ?? false
    );

    return {
        ...addApolloState(
            client,
            (await addGlobalQuery(
                {
                    props: {
                        entry: data.entry,
                        suppressCookieConsent,
                    },
                },
                context.locale
            )) as { props: Record<string, unknown> }
        ),
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    // const client = initApolloClient(defaultLanguage);

    // const { data } = await client.query<PagePathsQuery>({
    //     query: PagePathsDocument,
    // });

    const paths: any[] =
        // data.entries
        //     ?.filter(entry => entry && popularPages.includes(`/${entry.language ?? defaultLanguage}/${entry.uri}/`))
        //     .map(entry => {
        //         const uri = entry?.uri?.split('/') ?? [];
        //         return {
        //             params: {
        //                 locale: entry?.language ?? defaultLanguage,
        //                 uri,
        //             },
        //         };
        //     }) ??
        [];

    // eslint-disable-next-line no-console
    // console.log(`Page.getStaticPaths: including ${paths.length} out of ${data.entries?.length ?? 0} paths`);

    return {
        paths,
        fallback: 'blocking',
    };
};

export default Page;
