import React, { FC, useMemo } from 'react';
import { BreadcrumbFragment, MenuItemPageFieldsFragment, MenuItemPageRecursiveFragment } from '../generated/graphql';
import styled from 'styled-components';
import Link from './Link';
import ArrowRight from './icons/ArrowRight';
import { Flex } from '@rebass/grid';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';

type Uri = MenuItemPageFieldsFragment['uri'];

// do you like recursive functions? because boy do I have a surprise for you!

const buildBreadcrumb = (flatMenu: MenuItemPageFieldsFragment[], uri: Uri): MenuItemPageFieldsFragment[] => {
    const crumb = flatMenu.find(page => page?.uri === uri);

    if (crumb) {
        if (crumb.parent?.uri) {
            return [crumb, ...buildBreadcrumb(flatMenu, crumb.parent.uri)];
        }

        return [crumb];
    }
    return [];
};

const flattenMenu = (menu: MenuItemPageRecursiveFragment[]) =>
    menu.flatMap(item => {
        const flat = [item];
        if (item.children?.length) {
            flat.push(...flattenMenu(item.children as MenuItemPageRecursiveFragment[]));
        }
        return flat;
    });

const Breadcrumb: FC<React.PropsWithChildren<{ menu?: BreadcrumbFragment[]; uri: Uri }>> = ({ menu = [], uri }) => {
    const flattenedMenu = useMemo(
        () =>
            flattenMenu(
                menu?.flatMap(item => {
                    const el = item;
                    if (el?.__typename !== 'pages_page_Entry') {
                        return [];
                    }
                    return [el, ...(el.children ?? [])];
                }) as MenuItemPageRecursiveFragment[]
            ),
        [menu]
    );
    const breadcrumbParts = useMemo(() => buildBreadcrumb(flattenedMenu, uri).reverse(), [flattenedMenu, uri]);

    if (menu.length === 0) {
        return null;
    }

    return (
        <Flex mb={4} alignItems="center" flexWrap="wrap" className="gt-s">
            {breadcrumbParts.map((part, i) => {
                const isActive = part.uri === uri;
                return (
                    part.uri && (
                        <Part key={part.id}>
                            {!isActive ? (
                                <Link href={`/${part.uri}`}>
                                    <a>{part.title}</a>
                                </Link>
                            ) : (
                                <span>{part.title}</span>
                            )}
                            {i < breadcrumbParts.length - 1 && <ArrowRight width={12} height={12} />}
                        </Part>
                    )
                );
            })}
        </Flex>
    );
};

const Part = styled(Body).attrs({ variant: 'small' })`
    font-size: 1.4rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral[40]};
    margin-bottom: 0.4rem;

    > * + * {
        margin-right: 0.8rem;
        margin-left: 0.8rem;
        color: ${({ theme }) => theme.colors.neutral[40]};
    }

    a {
        text-decoration: none !important;
        color: ${({ theme }) => theme.colors.neutral[80]} !important;
        transition: 0.15s color;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none !important;
            color: ${({ theme }) => theme.colors.primary[60]} !important;
        }
    }
`;

export default Breadcrumb;
