import React, { SVGAttributes } from 'react';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';

const ArrowRight = (props: SVGAttributes<SVGElement>) => {
    const theme = useThemeContext();
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            xmlns="http://www.w3.org/2000/svg"
            color={theme.colors.primary[40]}
            {...props}
        >
            <g
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M11 1l3.75 3.75L16.2 6.2 19 9l-8 8M1.031 9h15.97" />
            </g>
        </svg>
    );
};
export default ArrowRight;
